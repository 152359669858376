const videoPrefixesAndNames = {
    "http://cdn.viktormatthews.com/minivideos2": [
        "test/juno-001.mp4",
        "test/juno-010.mp4",
        "test/juno-112.mp4",
        "test/juno_110_1_v.mp4",
        "test/juno_506_1_v.mp4",
        "test/juno_5239_005_1_v.mp4",
        "test/juno_5252_105_1_v.mp4",
        "test/juno_5253_160_1_v.mp4",
        "test/juno_5253_165_1_v.mp4",
        "test/juno_5253_170_1_v.mp4",
        "test/juno_5254_025_1_v.mp4",
        "test/juno_5254_030_1_v.mp4",
        "test/juno_5254_055_1_v.mp4"
    ]
}

export const videoUrls = Object.entries(videoPrefixesAndNames).flatMap(p => {
    const root = p[0]
    const names = p[1]
    return names.map(n => `${root}/${n}`)
})
