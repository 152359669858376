// const imageNames = [
//     "20190217-anon-035.jpg",
//     "20190217-anon-075.jpg",
//     // "20190322-test-009.jpg",
//     "20190405-junoltk-1-021.jpg",
//     "20190405-junoltk-2-017-all_business.jpg",
//     "20190405-junoltk-2-034-life_comes_at_you_fast.jpg",
//     "20190405-junoltk-2-036-intricacy.jpg",
//     "20190405-junoltk-2-037-sunlight.jpg",
//     "20190423-la-044.jpg",
//     "20190423-la-049.jpg",
//     "20190424-cindystarfall-0198.jpg",
//     "20190426-sandiego-082.jpg",
//     "20190426-sandiego-087.jpg",
//     "20190427-crystalwings2-0012.jpg",
//     "20190502-pittsburgh-011.jpg"
// ]

const imagePrefixesAndNames = {
    "https://cdn.viktormatthews.com/2019-images/_1000/2019-cover": [
        "20190217-anon-035.jpg",
        "20190217-anon-075.jpg",
        // "20190322-test-009.jpg",
        "20190405-junoltk-1-021.jpg",
        "20190405-junoltk-2-017-all_business.jpg",
        "20190405-junoltk-2-034-life_comes_at_you_fast.jpg",
        "20190405-junoltk-2-036-intricacy.jpg",
        "20190405-junoltk-2-037-sunlight.jpg",
        "20190423-la-044.jpg",
        "20190423-la-049.jpg",
        "20190424-cindystarfall-0198.jpg",
        "20190426-sandiego-082.jpg",
        "20190426-sandiego-087.jpg",
        "20190427-crystalwings2-0012.jpg",
        "20190502-pittsburgh-011.jpg"
    ],
    "http://cdn.viktormatthews.com/2018-images/_1000/2018-cover": [
        "20180127-alina-046-2-dream_date.jpg",
        "20180127-alina-070-2-slither.jpg",
        "20180127-alina-089-2-gigawatts.jpg",
        "20180127-alina-109-2-habitat.jpg",
        "20180127-alina-131-2-scratch.jpg",
        "20180202-sanjose-0021.jpg",
        "20180202-sanjose-0036.jpg",
        "20180328-sanjose-004.jpg",
        "20180328-sanjose-015.jpg",
        "20180328-sanjose-019.jpg",
        "20180329-anon-013-lust_for_life.jpg",
        "20180329-anon-027-2-vivid.jpg",
        "20180329-anon-035-3-chakras.jpg",
        "20180329-anon-038-2-unknown_2.jpg",
        "20180329-anon-040-my_maiden_my_mutant.jpg",
        "20180414-sanjose-007.jpg",
        "20180414-sanjose-021.jpg",
        "20180414-sanjose-023.jpg",
        "20180414-sanjose-025.jpg",
        "20180414-sanjose-028.jpg",
        "20180414-sanjose-029.jpg",
        "20180421-sanjose-008.jpg",
        "20180601-sanjose-08-dead_space.jpg",
        "20181112-philly-27.jpg"
    ],
    "http://cdn.viktormatthews.com/2017-images/_1000/2017-cover": [
        "20170101-random-031-colorway.jpg",
        // "20170104-philothene-026-penultimate.jpg",
        // "20170104-philothene-074-formation.jpg",
        // "20170104-philothene-076-popcorn.jpg",
        // "20170104-philothene-123-opulence.jpg",
        // "20170104-philothene-130-2-stargaze.jpg",
        // "20170104-philothene-sonya7-057-salome.jpg",
        // "20170104-philothene-sonya7-058-2-thrilled.jpg",
        // "20170104-philothene-sonya7-070-sandinista.jpg",
        "20170327-random-sonya7-019.jpg",
        "20170331-inertiacreeps-241-breakpoint.jpg",
        "20170421-dikimthoa-161.jpg",
        "20170421-dikimthoa-180.jpg",
        "20170421-dikimthoa-218.jpg",
        "20170421-dikimthoa-281-cobra.jpg",
        "20170421-dikimthoa-284-nightbreed.jpg",
        "20170421-dikimthoa-368-my_heart_is_throbbing.jpg",
        "20170421-dikimthoa-394-attempting_to_understand_women.jpg",
        "20170421-dikimthoa-410-let_the_martyrs_burn.jpg",
        "20170421-dikimthoa-423-heart_eyes.jpg",
        "20170519-santacruz-019-santa_cruz_19.jpg",
        "20170519-santacruz-037-santa_cruz_37.jpg",
        "20170519-santacruz-041-santa_cruz_41.jpg",
        "20170519-santacruz-047-santa_cruz_47.jpg",
        "20170527-random-041.jpg",
        "20170527-random-049.jpg",
        "20170715-roadtrip-011-palm_springs_11.jpg",
        "20170715-roadtrip-024-texas_24.jpg",
        "20170718-austin-055-austin_55.jpg",
        "20170718-jordanlehn-198.jpg",
        "20170718-jordanlehn-227.jpg",
        "20170719-gopro-0026-gopro_texas_26.jpg",
        "20170719-gopro-1512-los_angeles_gopro_1512.jpg",
        "20170720-neworleans-011-new_orleans_11.jpg",
        "20170720-neworleans-052-new_orleans_52.jpg",
        "20170720-neworleans-063.jpg",
        "20170804-atlanta-025-atlanta_25.jpg",
        "20170804-atlanta-029.jpg",
        "20170804-atlanta-058.jpg",
        "20170805-angierubio-leicam9-032.jpg",
        "20170805-angierubio-sonya7-070.jpg",
        "20170807-anonym-sonya7-204.jpg",
        "20170810-pureaty-sonya7-140.jpg",
        "20170813-harrisburg-035.jpg",
        "20170813-harrisburg-036-harrisburg_36.jpg",
        "20170818-mia-secreto-019-preordained.jpg",
        "20170818-mia-secreto-029-blind_date.jpg",
        "20170818-mia-secreto-044-gold.jpg",
        "20170818-mia-secreto-057-myopia.jpg",
        "20170818-mia-secreto-067-slippery.jpg",
        "20170818-talia-satania-041-when_you_feel_sexy_I_feel_dramatic.jpg",
        "20170818-talia-satania-048-I_have_so_many_questions.jpg",
        "20170818-talia-satania-055-diametrically_opposed.jpg",
        "20170818-talia-satania-061-nicotine.jpg",
        "20170823-philly-010-philadelphia_10.jpg",
        "20170826-pittsburgh-105-pittsburgh_105.jpg",
        "20170826-pittsburgh-278.jpg",
        "20170827-pittsburgh-night-001.jpg",
        "20170829-dikimthoa-125.jpg",
        "20170830-chicago-104-chicago_104.jpg",
        "20170830-chicago-123.jpg",
        "20170831-omaha-010-omaha_10.jpg",
        "20170902-denver-007-denver_7.jpg",
        "20170905-saltlakecity-114-salt_lake_city_114.jpg",
        "20170906-nattestid-022.jpg",
        "20170909-asiaperez-sonya7-037.jpg",
        "20170909-lasvegas-047.jpg",
        "20170909-lasvegas-115-las_vegas_115.jpg",
        "20170910-gopro-vegas2sanjose2-0740-vegas_to_san_jose_gopro_740.jpg",
        "20170923-nikkieislit-075-the_staring_contest.jpg",
        "20170923-nikkieislit-104-frost.jpg",
        "20170923-nikkieislit-121-Im_watching_you_watching_me.jpg",
        "20170923-nikkieislit-142-strapped.jpg"
    ],
    "http://cdn.viktormatthews.com/2016-images/_1000/2016-cover": [
        "20160319-sanjose-015.jpg",
        "20160319-sanjose-017-prayer_garden.jpg",
        "20160319-sanjose-019-pray_for_pink.jpg",
        "20160416-sanjose-022.jpg",
        "20160416-sanjose-034.jpg",
        "20160617-angela-0076.jpg",
        "20160617-angela-0083-lies.jpg",
        "20160617-angela-0136-statuesque.jpg",
        "20160618-bbygirl415-0038.jpg",
        "20160618-bbygirl415-0125-bellevue.jpg",
        "20160618-bbygirl415-0157-strands.jpg",
        "20160703-penelopereed-0008-candid.jpg",
        "20160703-penelopereed-0030-western.jpg",
        "20160703-penelopereed-0074-deadend.jpg",
        "20160703-penelopereed-0088-patrol.jpg",
        "20160703-penelopereed-0103-baphomet.jpg",
        "20160703-penelopereed-0107-rations.jpg",
        "20160703-penelopereed-0117-monument.jpg",
        "20160714-noela-meida-0136-speakeasy.jpg",
        "20160806-sanjose-0004-white_crane_style.jpg",
        "20160806-sanjose-0021-patches.jpg",
        "20160806-sanjose-0034-ensemble.jpg",
        "20160819-burgundy-0041-war_bride.jpg",
        "20160819-burgundy-0067-streep.jpg",
        "20160827-anonymous-0128-fuck_this_yearbook.jpg",
        "20161011-random-027-my_strip_mall_motherland.jpg",
        "20161011-random-058-girls.jpg",
        "20161013-franki-risk-027-the_waiting_room.jpg",
        "20161013-franki-risk-051-guestbook.jpg",
        "20161014-dinosawra-147-azubah.jpg",
        "20161016-philadelphia-051-the_mouth_in_the_sky.jpg",
        "20161018-atlanticcity-077-atlantic_city_77.jpg",
        "20161018-atlanticcity-087-atlantic_city_87.jpg",
        "20161018-atlanticcity-089-atlantic_city_89.jpg",
        "20161018-atlanticcity-100-atlantic_city_100.jpg",
        "20161018-atlanticcity-107-atlantic_city_107.jpg",
        "20161018-atlanticcity-117-atlantic_city_117.jpg",
        "20161019-philadelphia-061-chinatown_61.jpg",
        "20161019-philadelphia-076-jade.jpg"
    ],
    "http://cdn.viktormatthews.com/2015-img-sessions/_1000/2015-cover": [
        "20150101-kathleen-truffaut-20150101-0026-river.jpg",
        "20150101-kathleen-truffaut-20150101-0547-ears.jpg",
        "20150103-sesuna-0159-spiked.jpg",
        "20150103-sesuna-0354-hummingbird.jpg",
        "20150103-sesuna-0527-I_walk_the_line.jpg",
        "20150103-sesuna-0607-icicle.jpg",
        "20150103-sesuna-0905-trophy.jpg",
        "20150124-inertia-creeps-20150124-0021-this_is_my_world_and_these_are_my_rules.jpg",
        "20150124-inertia-creeps-20150124-0052-pacifist.jpg",
        "20150124-inertia-creeps-20150124-0298-edit-likes.jpg",
        "20150124-inertia-creeps-20150124-0372-edit-microdose_2.jpg",
        "20150124-inertia-creeps-20150124-0524-edit-fountainhead.jpg",
        "20150127-pepper-kester-20150127-0059-fresca.jpg",
        "20150127-pepper-kester-20150127-0109-straight.jpg",
        "20150127-pepper-kester-20150127-0152-threads.jpg",
        "20150127-pepper-kester-20150127-0234-broadway.jpg",
        "20150204-sarissle-20150204-0062-inglewood.jpg",
        "20150204-sarissle-20150204-0087-edit-mistaken.jpg",
        "20150204-sarissle-20150204-0138-edit-enchantment.jpg",
        "20150204-sarissle-20150204-0167-edit-2-nest.jpg",
        "20150204-sarissle-20150204-0374-edit-graph.jpg",
        "20150204-sarissle-20150204-0434-parabola.jpg",
        "20150204-sarissle-20150204-0478-edit-turlington.jpg",
        "20150204-sarissle-20150204-0504-edit-stalk_2.jpg",
        "20150204-sarissle-20150204-0510-edit-twirl.jpg",
        "20150313-20150313-helyn-0212-richter.jpg",
        "20150317-20150317-ayumi-0215-truffles.jpg",
        "20150321-20150321-charlotte-0104-revisited.jpg",
        "20150321-20150321-charlotte-0131-theresa.jpg",
        "20150328-20150328-random-0015-always.jpg",
        "20150328-20150328-random-0032.jpg",
        "20150508-20150508-sumalee-0025-edit-starlord.jpg",
        "20150508-20150508-sumalee-0065-edit-providence.jpg",
        "20150508-20150508-sumalee-0090-edit-benedict.jpg",
        "20150508-20150508-sumalee-0133-edit-2-peacock.jpg",
        "20150508-20150508-sumalee-0137-edit-telemachus_2.jpg",
        "20150508-20150508-sumalee-0238-unbearable.jpg",
        "20150508-20150508-sumalee-0247-edit-refused.jpg",
        "20150508-20150508-sumalee-0293-edit-power.jpg",
        "20150508-20150508-sumalee-0294-edit-ashes.jpg",
        "20150508-20150508-sumalee-0299-edit-early.jpg",
        "20150703-20150703-sanjose-0041-nocturn.jpg",
        "20150721-sage-ferril-0298-kubla.jpg",
        "20150721-sage-ferril-0389-flatliner.jpg",
        "20150721-sage-ferril-0408-2-followers.jpg",
        "20150721-sage-ferril-0420-2-warfighter.jpg",
        "20150721-sage-ferril-0442-vector.jpg",
        "20150721-sage-ferril-0463-the_pale_rainbow.jpg",
        "20150722-miami-0034-roadkill.jpg",
        "20150722-miami-0035-infinite.jpg",
        "20150724-lizzy-lamb-0012-thirds.jpg",
        "20150724-lizzy-lamb-0060-edit-replicant.jpg",
        "20150724-lizzy-lamb-0077-1955.jpg",
        "20150724-lizzy-lamb-0080-nachtmahr.jpg",
        "20150724-lizzy-lamb-0114-speed.jpg",
        "20150724-lizzy-lamb-0132-confidential.jpg",
        "20150724-lizzy-lamb-0148-amacano.jpg",
        "20150724-lizzy-lamb-0168-the_globalist.jpg",
        "20150724-lizzy-lamb-0216-boundaries.jpg",
        "20150724-lizzy-lamb-0449-volcel.jpg",
        "20150724-lizzy-lamb-0470-vapor.jpg",
        "20150724-lizzy-lamb-0487-megalith.jpg",
        "20150724-lizzy-lamb-0516-edit-sleight.jpg",
        "20150903-anon-0021-edit-solar_wind.jpg",
        "20150903-anon-0090-loop.jpg",
        "20150903-anon-0095-franchise.jpg",
        "20150903-anon-0123-edit-koi_no_yokan.jpg",
        "20150903-anon-0157-my_fantasies_are_not_unrealistic.jpg",
        "20150903-anon-0192-maidenform.jpg",
        "20150903-anon-0195-ivanka.jpg",
        "20150903-anon-0200-benghazi.jpg",
        "20150916-reya-sunshine-0018-fission.jpg",
        "20150916-reya-sunshine-0057-trenchant.jpg",
        "20150916-reya-sunshine-0059-bivalvia.jpg",
        "20151002-gaiah-0086-edit-norad.jpg",
        "20151023-sanjose-009-dress_for_less.jpg",
        "20151027-sierra-moreno-048-jane_doe.jpg",
        "20151027-sierra-moreno-080-edit-miscreant.jpg",
        "20151027-sierra-moreno-119-persimmon.jpg",
        "20151104-danika-flores-0125-edit-red.jpg",
        "20151113-caitlin-024-edit-glitter.jpg",
        "20151113-caitlin-118-edit-to_swim_you_have_to_swallow.jpg",
        "20151128-sarissle-137-honey_Im_home.jpg",
        "20151128-sarissle-143-trail_of_tears.jpg",
        "20151128-sarissle-171-nothing.jpg",
        "20151128-sarissle-191-criminal_justice.jpg",
        "20151128-sarissle-224-kill_calvin.jpg",
        "20151128-sarissle-sonya7-045-the_physical_world.jpg",
        "20151128-sarissle-sonya7-050-cursed.jpg"
    ],
    "http://cdn.viktormatthews.com/2014-img-sessions/_1000/2014-cover": [
        "20140116-c-063-tigershark.jpg",
        "20140116-c-104-prestige.jpg",
        "20140125-c-092-Im_not_even_supposed_to_be_here_today.jpg",
        "20140215-jordan-bunniie-20140215-0062-shes_lost_control.jpg",
        "20140215-jordan-bunniie-20140215-0115-the_heat_death_of_the_universe.jpg",
        "20140215-jordan-bunniie-20140215-0159-liara.jpg",
        "20140215-jordan-bunniie-20140215-0345-marseille.jpg",
        "20140215-jordan-bunniie-20140215-0368-slang.jpg",
        "20140215-jordan-bunniie-20140215-0424-2-omega.jpg",
        "20140215-jordan-bunniie-20140215-0446-tripwire.jpg",
        "20140219-cam-damage-0153-venom.jpg",
        "20140219-cam-damage-0257-watch.jpg",
        "20140219-cam-damage-0422-2-darknet.jpg",
        "20140219-cam-damage-0468-the_red_menace.jpg",
        "20140219-cam-damage-2-594-centrefold.jpg",
        "20140219-cam-damage-2-633-barnes.jpg",
        "20140219-cam-damage-2-638-I_see_what_you_see.jpg",
        "20140308-s-003-2-arena.jpg",
        "20140308-s-011-math.jpg",
        "20140308-s-023-reserved.jpg",
        "20140315-inertia-creeps-0397-renault.jpg",
        "20140315-inertia-creeps-043-2-smoke.jpg",
        "20140315-inertia-creeps-0435-sixteen.jpg",
        "20140315-inertia-creeps-0453-vision.jpg",
        "20140315-inertia-creeps-0456-sleep.jpg",
        "20140315-inertia-creeps-0533-right.jpg",
        "20140315-inertia-creeps-0544-mercy.jpg",
        "20140315-inertia-creeps-056-game_over.jpg",
        "20140315-inertia-creeps-0587-up.jpg",
        "20140315-inertia-creeps-1042-lit_3.jpg",
        "20140315-inertia-creeps-146-waifu.jpg",
        "20140315-inertia-creeps-210-portal.jpg",
        "20140315-inertia-creeps-249-life_feeds_on_life.jpg",
        "20140315-inertia-creeps-724-francois.jpg",
        "20140315-inertia-creeps-832-stanozolol.jpg",
        "20140315-inertia-creeps-902-pizarro.jpg",
        "20140315-inertia-creeps-997-confidential.jpg",
        "20140316-DSC00296-citadel.jpg",
        "20140322-x-030-stream.jpg",
        "20140322-x-035-him_who_eats_time.jpg",
        "20140322-x-097-institution.jpg",
        "20140325-x-065-memento.jpg",
        "20140327-s-106-convicted.jpg",
        "20140327-s-211-suspiria.jpg",
        "20140405-20140405-a-raw-muse-0267-inches.jpg",
        "20140406-avery-023-prom.jpg",
        "20140406-avery-038-olympia.jpg",
        "20140406-avery-079-headshot.jpg",
        "20140406-avery-130-wealth.jpg",
        "20140412-shara-145-haze.jpg",
        "20140412-shara-380-fundamentalism.jpg",
        "20140412-shara-428-skew_2.jpg",
        "20140414-caciazoo-20140414-0702-kill.jpg",
        "20140414-caciazoo-20140414-0795-tacoma.jpg",
        "20140414-caciazoo-20140414-0859-diderot.jpg",
        "20140415-esther-rose-0036-two.jpg",
        "20140415-esther-rose-0062-avalon.jpg",
        "20140415-esther-rose-0070-sapien.jpg",
        "20140415-myra-sierra-570-charcoal.jpg",
        "20140510-x-074-jury.jpg",
        "20140513-p-019-pipe.jpg",
        "20140513-p-053-2-lotus.jpg",
        "20140513-p-089-audi.jpg",
        "20140517-20140517-miki-hamano-0321-the_bat_signal.jpg",
        "20140517-20140517-miki-hamano-0413-this_is_how_I_solve_my_problems.jpg",
        "20140517-20140517-miki-hamano-0496-precocious.jpg",
        "20140517-20140517-miki-hamano-0585-research.jpg",
        "20140517-20140517-miki-hamano-0641-the_eye_in_the_pyramid.jpg",
        "20140705-luna-leung-0384-pop.jpg",
        "20140705-luna-leung-0406-primer.jpg",
        "20140705-luna-leung-0408-andromeda.jpg",
        "20140705-luna-leung-0419-cracked.jpg",
        "20140705-luna-leung-0456-bloom.jpg",
        "20140722-iva-136-2-judith.jpg",
        "20140722-iva-144-2-polly_1.jpg",
        "20140728-random-20140728-0025-headquarters.jpg",
        "20140731-random-20140731-0098-blue.jpg",
        "20140804-jordan-lehn-20140804-0052-kinda.jpg",
        "20140804-jordan-lehn-20140804-0063-arrabbiata.jpg",
        "20140804-jordan-lehn-20140804-0144-tigerstyle.jpg",
        "20140810-devi-329-2-cube.jpg",
        "20140831-sanjose-008-tournament.jpg",
        "20140831-sanjose-017-pipes.jpg",
        "20140906-anon-0203-deadlock.jpg",
        "20140906-anon-0220-opera.jpg",
        "20140906-anon-0234-I_am_a_god.jpg",
        "20140906-anon-0242-blizzard.jpg",
        "20140914-palebreast-0807-sold.jpg",
        "20140928-ayumi-20140928-0107-intertwined.jpg",
        "20140928-ayumi-20140928-0130-epistrophy.jpg",
        "20140928-ayumi-20140928-0141-details.jpg",
        "20140928-ayumi-20140928-0202-pagoda.jpg",
        "20140928-ayumi-20140928-0210-carbine.jpg",
        "20140928-ayumi-20140928-0376-almost.jpg",
        "20140928-ayumi-20140928-0426-pelt.jpg",
        "20140928-ayumi-20140928-0452-tendons_2.jpg",
        "20140930-priscella-stefanie-20140930-0015-I_cant_move.jpg",
        "20140930-priscella-stefanie-20140930-0049-being_exhausted_is_exhausting.jpg",
        "20140930-priscella-stefanie-20140930-0145-enthralled.jpg",
        "20140930-priscella-stefanie-20140930-0183-8_years.jpg",
        "20140930-priscella-stefanie-20140930-0239-skull_and_bones.jpg",
        "20140930-priscella-stefanie-20140930-0437-I_dreamed_of_you_but_it_wasnt_you.jpg",
        "20140930-priscella-stefanie-20140930-0475-apex.jpg",
        "20140930-priscella-stefanie-20140930-0564-tasteful.jpg",
        "20140930-priscella-stefanie-20140930-0691-etiquette.jpg",
        "20141002-DSC00472-eyes.jpg",
        "20141220-anabelle-dang-0055-hemingway.jpg",
        "20141220-anabelle-dang-0113-olajuwan.jpg",
        "20141220-anabelle-dang-0154-mega.jpg"
    ],
    "https://cdn.viktormatthews.com/2013-img-sessions/medium/2013-cover": [
        "20130101-random-20130101-17-edit-2-lost_highway.jpg",
        "20130101-random-20130101-9-edit-columns.jpg",
        "20130126-amy-20130126-113-edit-2-starscream.jpg",
        "20130126-amy-20130126-173-edit-the_sorceress,_part_two.jpg",
        "20130126-amy-20130126-450-edit-2-vengeance.jpg",
        "20130126-amy-20130126-80-edit-fugue.jpg",
        "20130202-z-20130202-632-edit-killer_hills,_part_two.jpg",
        "20130210-makenzi-20130210-1000-edit-native.jpg",
        "20130210-makenzi-20130210-1019-edit-ghostface.jpg",
        "20130210-makenzi-20130210-735-edit-photon.jpg",
        "20130210-makenzi-20130210-747-edit-2-animus.jpg",
        "20130210-makenzi-20130210-775-edit-2.jpg",
        "20130210-makenzi-20130210-929-edit-wound.jpg",
        "20130216-x-20130216-649-edit-enter.jpg",
        "20130216-x-20130216-658-edit-lines.jpg",
        "20130216-x-20130216-661-edit-courtside.jpg",
        "20130224-jen-20130224-26-edit-tread.jpg",
        "20130224-jen-20130224-296-edit-frontier.jpg",
        "20130224-jen-20130224-3-edit-homeland.jpg",
        "20130224-jen-20130224-547-edit-horror.jpg",
        "20130224-jen-20130224-77-edit-2-day_zero.jpg",
        "20130309-z-20130309-30-edit-exhibition.jpg",
        "20130309-z-20130309-36-edit-equals.jpg",
        "20130309-z-20130309-37-edit-check.jpg",
        "20130309-z-20130309-39-edit-geometry.jpg",
        "20130316-z-20130316-10.jpg",
        "20130317-angel-20130317-425-edit-2-heroine.jpg",
        "20130317-angel-20130317-45-edit-3-protagonist.jpg",
        "20130317-angel-20130317-477-edit-2-lucid.jpg",
        "20130317-angel-20130317-487-edit-exam.jpg",
        "20130323-z-20130323-10.jpg",
        "20130323-z-20130323-9.jpg",
        "20130407-amy-20130407-173-edit-a_shield_and_a_spear.jpg",
        "20130407-amy-20130407-256-edit-touched.jpg",
        "20130407-amy-20130407-286-edit-tango_1.jpg",
        "20130407-amy-20130407-353-edit-in_this_I_am_true.jpg",
        "20130407-amy-20130407-68-edit-empire.jpg",
        "20130420-z-20130420-15-edit-the_hive.jpg",
        "20130420-z-20130420-17-edit-antennae.jpg",
        "20130420-z-20130420-23-edit-sundown.jpg",
        "20130428-lauren-20130428-10-edit-frontline.jpg",
        "20130428-lauren-20130428-235-edit-defense.jpg",
        "20130428-lauren-20130428-286-edit-guardian.jpg",
        "20130505-c-20130505-17-edit-the_stranger,_part_one.jpg",
        "20130505-c-20130505-268-edit-pause.jpg",
        "20130505-c-20130505-27-edit-ultraviolence.jpg",
        "20130505-c-20130505-296-edit-rusty_3.jpg",
        "20130505-c-20130505-341-edit-savior.jpg",
        "20130505-c-20130505-63-edit-the_other_me.jpg",
        "20130505-c-20130505-66-edit-the_stranger,_part_two.jpg",
        "20130518-m-20130518-126-edit-the_faster_blade.jpg",
        "20130518-m-20130518-184-edit-2-red_dragon.jpg",
        "20130518-m-20130518-186-edit-madonna.jpg",
        "20130518-m-20130518-210-edit-hatch.jpg",
        "20130518-m-20130518-477-edit-cosmonaut.jpg",
        "20130518-m-20130518-504-edit-ironborn.jpg",
        "20130518-m-20130518-715-edit-forever.jpg",
        "20130518-m-20130518-730-edit-stone.jpg",
        "20130518-m-20130518-734-edit-revolver.jpg",
        "20130518-m-20130518-742-edit-amnesia.jpg",
        "20130518-m-20130518-84-edit-2-first_lady.jpg",
        // "20130519-z-20130519-165.jpg",
        "20130525-l-20130525-101-edit-one_thousand.jpg",
        "20130525-l-20130525-154-edit-producer.jpg",
        "20130525-l-20130525-208-edit-gazelle.jpg",
        "20130525-l-20130525-25-edit-source.jpg",
        "20130614-d-20130614-238-edit-obscura.jpg",
        "20130614-d-20130614-399-edit-reptile.jpg",
        "20130622-p-20130622-117-edit-chernobyl.jpg",
        "20130622-p-20130622-136-edit-still.jpg",
        "20130622-p-20130622-209-edit-adjustment_2.jpg",
        "20130622-p-20130622-232-edit-raskolnikov.jpg",
        "20130622-p-20130622-500-edit-bandito.jpg",
        "20130622-p-20130622-553-edit-jehovah.jpg",
        "20130622-p-20130622-627-edit-hygienist.jpg",
        "20130622-p-20130622-629-edit-2-copperhead.jpg",
        "20130622-p-20130622-91-edit-stop.jpg",
        "20130622-p-20130622-94-edit-hypnosis.jpg",
        "20130622-p-20130622-99-edit-2-star.jpg",
        "20130721-d-20130721-107.jpg",
        "20130721-d-20130721-134-edit-dallas.jpg",
        "20130721-d-20130721-140-edit-into_the_wild.jpg",
        "20130721-d-20130721-141-edit-portland.jpg",
        "20130721-d-20130721-228-edit-never.jpg",
        "20130721-d-20130721-241-edit-always.jpg",
        "20130721-d-20130721-328-edit-vanderbilt.jpg",
        "20130721-d-20130721-355-edit-2-persistence.jpg",
        "20130721-d-20130721-358-edit-decoy.jpg",
        "20130721-d-20130721-441-edit-condemned.jpg",
        "20130721-d-20130721-442-edit-your_bones_are_red.jpg",
        "20130721-d-20130721-457-edit-charm.jpg",
        "20130721-d-20130721-497-edit-trapped.jpg",
        "20130721-d-20130721-585-edit-gold.jpg",
        "20130721-d-20130721-598-edit-2-reticule.jpg",
        "20130721-d-20130721-617-edit-ceramic.jpg",
        "20130721-d-20130721-631-edit-carcinogen.jpg",
        "20130721-d-20130721-70-edit-3-galapagos.jpg",
        "20130721-d-20130721-73-edit-taxonomy.jpg",
        "20130721-d-20130721-76-edit-bronze.jpg",
        "20130721-d-20130721-86.jpg",
        "20130727-johannie-20130727-109-edit-2-knives.jpg",
        "20130727-johannie-20130727-137-edit-windowlicker.jpg",
        "20130727-johannie-20130727-156-edit-2-lennon.jpg",
        "20130727-johannie-20130727-261-edit-darker.jpg",
        "20130727-johannie-20130727-283-edit-cersei.jpg",
        "20130727-johannie-20130727-287-edit-occult.jpg",
        "20130727-johannie-20130727-29-edit-ballet.jpg",
        "20130727-johannie-20130727-294-edit-your_skull_is_red.jpg",
        "20130727-johannie-20130727-312-edit-to_pluck,_and_smother,_and_inhale.jpg",
        "20130727-johannie-20130727-471-edit-riviera.jpg",
        "20130727-johannie-20130727-547-edit-compromised.jpg",
        "20130727-johannie-20130727-64-edit-idaho.jpg",
        "20130727-johannie-20130727-679-edit-legalese.jpg",
        "20130727-johannie-20130727-85-edit-tackle.jpg",
        "20130818-q-20130818-118-edit-ninety.jpg",
        "20130818-q-20130818-174-edit-magazines.jpg",
        "20130818-q-20130818-175-edit-decoration.jpg",
        "20130831-mercedes-136-edit-elevator.jpg",
        "20130831-mercedes-139-fame_kills.jpg",
        "20130831-mercedes-19-edit-judgement_day.jpg",
        "20130831-mercedes-212-edit-this_is_the_end.jpg",
        "20130831-mercedes-226-ghost.jpg",
        "20130831-mercedes-282-edit-cryptex.jpg",
        "20130831-mercedes-46-edit-2-gravity.jpg",
        "20130831-mercedes-5-edit-unrest.jpg",
        "20130901-sf00003-edit-trig.jpg",
        "20130901-sf00028-edit-there.jpg",
        "20130901-sf00040-edit-across_the_sea.jpg",
        "20130908-dusky-64-edit-loathing_part_5.jpg",
        "20130922-leila-78-edit-2-interview.jpg",
        "20131014-DSC00086-edit-prophet.jpg",
        "20131014-DSC00087-edit-tomb.jpg",
        "20131022-shawna-158-edit-sermon.jpg",
        "20131022-shawna-161-edit-communion.jpg",
        "20131022-shawna-202-edit-shell.jpg",
        "20131022-shawna-222-edit-narcolepsy.jpg",
        "20131022-shawna-227-edit-cursive.jpg",
        "20131022-shawna-231-edit-flank.jpg",
        "20131023-DSC00103-edit-borders.jpg",
        "20131023-DSC00116-edit-march.jpg",
        "20131023-lucy-112-edit-mourn.jpg",
        "20131023-lucy-145-edit-mantis.jpg",
        "20131023-lucy-150-edit-tyrant.jpg",
        "20131023-lucy-20-edit-nucleus.jpg",
        "20131023-lucy-203-edit-nietzsche.jpg",
        "20131023-lucy-204-edit-rollins_is_my_god.jpg",
        "20131023-lucy-272-edit-cross.jpg",
        "20131023-lucy-278-edit-negative.jpg",
        "20131023-lucy-295-edit-oriole.jpg",
        "20131023-lucy-309-edit-thief.jpg",
        "20131023-lucy-69-edit-caravan.jpg",
        "20131024-lizzy-156-sacrifice.jpg",
        "20131024-lizzy-244-edit-crossbones.jpg",
        "20131024-lizzy-291-edit-bones.jpg",
        "20131024-lizzy-296-edit-teeth.jpg",
        "20131024-lizzy-298-edit-moderate.jpg",
        "20131024-lizzy-36-edit-well_adjusted.jpg",
        "20131025-DSC00166-edit-wrath.jpg",
        "20131025-DSC00201-edit-saucer-like.jpg",
        "20131025-DSC00204-edit-bread.jpg",
        "20131025-DSC00237-edit-contact.jpg",
        // "20131118-lizzy-011-edit-2-defendant.jpg",
        "20131118-lizzy-091-edit-burst.jpg",
        "20131118-lizzy-097-edit-missing.jpg",
        "20131118-lizzy-265-edit-bedlam.jpg",
        "20131119-lucy-jaye-20131119-0066-edit-harmony.jpg",
        "20131119-lucy-jaye-20131119-0352-shame.jpg",
        "20131119-lucy-jaye-20131119-0371-chef.jpg",
        "20131119-lucy-jaye-20131119-0430-royal_2.jpg",
        "20131119-lucy-jaye-20131119-0438-carnival.jpg",
        "20131119-lucy-jaye-20131119-0455-quiet_desperation.jpg",
        "20131119-lucy-jaye-20131119-0535-snakepit.jpg",
        "20131119-lucy-jaye-20131119-0577-fists.jpg",
        "20131125-DSC00243-edit-reprieve.jpg",
        "20131125-DSC00246-edit-jessica.jpg",
        "20131125-DSC00250-edit-grill.jpg",
        "20131214-meiko-099-edit-anon.jpg",
        "20131214-meiko-129-edit-terabyte.jpg",
        "20131214-meiko-175-edit-palahniuk.jpg",
        "20131214-meiko-178-edit-velvet.jpg",
        "20131214-meiko-180-edit-homage.jpg",
        "20131214-meiko-185-edit-eros.jpg",
        "20131214-meiko-189-edit-military.jpg",
        "20131214-meiko-askara-20131214-0258-arrest.jpg",
        "20131214-meiko-askara-20131214-0317-feline.jpg",
        "20131214-meiko-askara-20131214-0400-california_3.jpg",
        "20131214-meiko-askara-20131214-0404-hype.jpg",
        "20131214-meiko-askara-20131214-0436-neon.jpg",
        "20131214-meiko-askara-20131214-0600-slave.jpg",
        "20131214-meiko-askara-20131214-0610-edit-rope.jpg",
        "20131224-sophia-jade-20131224-0093-edit-2-invariant_3.jpg",
        "20131224-sophia-jade-20131224-0172-edit-joules_2.jpg",
        "20131224-sophia-jade-20131224-0400-edit-employment.jpg",
        "20131224-sophia-jade-20131224-0453-edit-foucault_2.jpg"
    ]
}

export const imageUrls = Object.entries(imagePrefixesAndNames).flatMap(p => {
    const root = p[0]
    const names = p[1]
    return names.map(n => `${root}/${n}`)
})
