// import {createAuthProvider} from 'react-token-auth';

import { decodeToken, useJwt } from "react-jwt";

const loadConfig = () => {
    const apiEnv = process.env.REACT_APP_VIM_ENV    
    console.log(`loadConfig process.env: ${JSON.stringify(process.env)}`)

    let config;
    if (apiEnv == "local") {
        config = {
            contentApiUrl: `http://${window.location.hostname}:5001`
        }
    } else {
        config = {
            // contentApiUrl: "https://809u7u93ei.execute-api.us-east-1.amazonaws.com/dev"
            contentApiUrl: "https://api.viktormatthews.com"
        }
    }

    return config
}

const config = loadConfig()
console.log(`config:${JSON.stringify(config)}`)

function _buildContentApiUrl(path) {
    return `${config.contentApiUrl}${path}`
}

export class AuthClient {

    constructor() {
        this.token = null
        this.tokenDecoded = null
    }

    // example parsed token
    // AuthClient fetchToken tokenParsed:{"iat":1701749890,"exp":1701749950,"jti":"2a708566-7100-428a-b216-e7fdb5668771","id":"meh","rls":"","rf_exp":1701750190}

    // either returns current token,
    // or fetches new token is there is no current token or if the refresh period has expired
    // or refreshes token
    // returns a promise that resolves to the token
    fetchToken() {
        let fetchTokenPromise = null

        if (this.token == null) {
            fetchTokenPromise = this._newToken.bind(this)
        } else {
            const now = Date.now()
            const secondsUntilRefreshExpiration = Math.ceil((this.refreshExpirationDate - now) / 1000)
            const secondcUntilTokenExpiration = Math.ceil((this.tokenExpirationDate - now) / 1000)
            console.log(`AuthClient fetchToken secondsUntilRefreshExpiration:${secondsUntilRefreshExpiration} secondsUntilTokenExpiration:${secondcUntilTokenExpiration}`)

            // TODO it seems that if I refresh token within N seconds of expiration, it's too soon and I get an HTTP 425 response
            // do I need to define an additional window to add to expiration time before refreshing?
            // expirationWindow = 

            if (secondsUntilRefreshExpiration < 0) {
                // if token refresh period has expired, fetch new token
                console.log("AuthClient fetchToken refresh period expired, fetching anew")
                fetchTokenPromise = this._newToken.bind(this)
            } else if (secondcUntilTokenExpiration < 0) {
                // if token has expired, refresh it
                console.log("AuthClient fetchToken token expired, refreshing")
                fetchTokenPromise = this._refreshToken.bind(this)
            }

            // else, we don't set the fetchTokenPromise, while results in just returning the token below
        }

        // console.log(`AuthClient fetchToken fetchTokenPromise:${fetchTokenPromise}`)
        if (fetchTokenPromise != null) {
            return fetchTokenPromise().then((token) => {
                this.token = token
                this.tokenDecoded = decodeToken(this.token)
                console.log(`AuthClient fetchToken token:${this.token} tokenDecoded:${JSON.stringify(this.tokenDecoded)}`)

                this.refreshExpirationDate = new Date(0);
                this.refreshExpirationDate.setUTCSeconds(this.tokenDecoded.rf_exp);
                
                this.tokenExpirationDate = new Date(0);
                this.tokenExpirationDate.setUTCSeconds(this.tokenDecoded.exp);
    
                return token
            })
        } else {
            console.log("AuthClient fetchToken reusing existing token")
            return Promise.resolve(this.token)
        }
    }

    hasToken() {
        return this.token != null
    }

    _newToken() {
        // const reqBody = JSON.stringify({u:"meh", p:"hi"})

        const reqBody = JSON.stringify({
            t: Date.now(),
            n: Math.round(Math.random() * Number.MAX_SAFE_INTEGER)
        })

        return fetch(_buildContentApiUrl("/auth/token/new"), {
            method: 'post',
            body: reqBody
        })
        .then(r => {
            // TODO: can I share this logic?
            if (r.status != 200) {
                Promise.reject(`unexpected response status: ${r.status}`)
            }
            return r.json()
        })
        .then(token => {
            if (token.access_token) {
                return token.access_token
            } else {
                Promise.reject("no token fetched")
            }
        })
    }

    _refreshToken() {
        return fetch(_buildContentApiUrl("/auth/token/refresh"), {
            headers: { "Authorization": `Bearer ${this.token}`}
        })
        .then(r => {
            if (r.status != 200) {
                Promise.reject(`unexpected response status: ${r.status}`)
            }
            return r.json()
        })
        .then(token => {
            console.log(`AuthClient refreshToken token:${JSON.stringify(token)}`)
            return token.access_token
        })
    }

}

export class ContentClient {

    constructor({shouldAuthenticate=true}) {
        console.log(`ContentClient constructor shouldAuthenticate:${shouldAuthenticate}`)

        this.authClient = null
        if (shouldAuthenticate) {
            this.authClient = new AuthClient()
        }
    }

    // returns Promise
    _maybeAuth() {
        // console.log(`ContentClient _authCheck authClient:${this.authClient}`)

        if (this.authClient != null) {
            return this.authClient.fetchToken()
        } else {
            return Promise.resolve("auth not required")
        }
    }

    _baseRequestHeaders() {
        const h = { "accept": "application/json" }

        // TODO: I wanted to just use cookies here, but at a glance it isn't supported for cross-origin requests
        if (this.authClient.hasToken()) {
            h["Authorization"] = `Bearer ${this.authClient.token}`
        }
        return h
    }

    // TODO: add query params to:
    // fetch only videos
    // fetch images with matching tags
    fetchRandom(opts={}) {
        const count = opts.count || 5

        const fetchContent = () => { 
            return fetch(_buildContentApiUrl(`/content?random=1&count=${count}`), 
                { headers: this._baseRequestHeaders() })
            .then(response => {
                if (response.status != 200) {
                    Promise.reject(`unexpected response status: ${response.status}`)
                }
                return response.json()
            })
        }
        return this._maybeAuth().then(() => fetchContent())
    }

    fetchWithName(imgName, opts={}) {
        const apiUrl = _buildContentApiUrl("/image")
        console.log(`ContentClient fetchWithName apiUrl:${apiUrl}`)

        return fetch(apiUrl, {
            headers: { "accept": "application/json" }
        })
        .then(response => response.json())
    }

    fetchByModelName(modelName, opts={}) {
        console.log(`ContentClient fetchByModelName modelName:${modelName} opts:${JSON.stringify(opts)}`)

        const count = opts.count || 5
        const startIdx = opts.startIndex || 0

        const fetchContent = () => { 
            return fetch(_buildContentApiUrl(`/content?person=${modelName}&start_idx=${startIdx}&count=${count}`), 
                { headers: this._baseRequestHeaders() })
            .then(response => {
                if (response.status != 200) {
                    Promise.reject(`unexpected response status: ${response.status}`)
                }
                return response.json()
            })
        }
        return this._maybeAuth().then(() => fetchContent())
    }

    like(contentUrl, extras, options, event) {
        console.log(`ContentClient like contentUrl:${contentUrl} extras=${extras} options=${options} event=${event}`)
    
        event.preventDefault()
    
        // TODO call api to engage with image
        const apiUrl = _buildContentApiUrl("/like")
        return fetch(apiUrl, { 
                method: "POST",
                body: JSON.stringify({ content_url: contentUrl, extras: extras, options: options })
            }).then(response => {
                if (response.status != 200) {
                    throw new Error(`ContentClient like response.status:${response.status}`)
                }

                // change heart icon
            })
    }
}
