// import logo from './logo.svg';
import './App.css';

// import { Player } from 'video-react';
// import './node_modules_link/video-react/dist/video-react.css';

import { useEffect, useState } from 'react';

import { ErrorBoundary } from "react-error-boundary";

import InfiniteScroll from 'react-infinite-scroll-component';

import ReactPlayer from 'react-player'

import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
    useParams
  } from "react-router-dom";

import { selectRandom } from "./common"
import { imageUrls } from "./ImageIndex"
import { videoUrls } from "./VideoIndex"
import { ContentClient } from "./contentClient"
import { render } from '@testing-library/react';

export default function App() {
    // TODO improve error messaging
    const fallback = (
        renderContent(
            <div>Something went wrong</div>
        )
    )

    return (
        <Router>
            <Routes>
                <Route path="/museum" element={ <ContentContainer generator={ new MuseumContentGenerator() } /> } />
                <Route path="/single" element={ <ContentContainer generator={ new SingleContentGenerator() } /> } />
                <Route path="/model/:modelName" element={ <ErrorBoundary fallback={fallback}><InfiniteContentComponent/></ErrorBoundary> } /> } />
                <Route path="/" element={ <ErrorBoundary fallback={fallback}><InfiniteContentComponent/></ErrorBoundary> } />                
                <Route path="*" element={ <NotFoundComponent/> } />                
            </Routes>
        </Router>
    )
}

function ContentContainer({ generator }) {

    const [content, setContent] = useState([])
    const [error, setError] = useState([])

    const query = new URLSearchParams(useLocation().search);

    useEffect(() => {
        if (generator == null) {
            throw Exception("generator reqired")
        }
        console.log(`ContentContainer useEffect generator:${Object.keys(generator)} query:${query}`)
    
        let contentSetter = setContent.bind(this)

        generator.generate({ query:query, contentSetter:contentSetter })
            .then(content => setContent(content))
            .catch(error => {
                console.error(error)
                setError(error) 
                setContent(error.toString())
            })
    }, [])

    console.log(`ContentContainer render`)

    return renderContent(content)    
}



class SingleContentGenerator {

    generate(props) {
        const query = props.query

        const showVideo = query.get("video")
        console.log(`showVideo:${showVideo}`)

        const urlContains = query.get("url_contains")
        console.log(`urlContains:${urlContains}`)

        var contentUrl
        if (showVideo != null && showVideo === "1" ) {
            contentUrl = selectRandom(videoUrls)
        } else {
            var maybeFilteredImageUrls
            if (urlContains) {
                maybeFilteredImageUrls = imageUrls.filter(url =>  url.includes(urlContains))
                console.log(`${maybeFilteredImageUrls.length} images after filtering`)
            } else {
                maybeFilteredImageUrls = imageUrls
            }

            contentUrl = selectRandom(maybeFilteredImageUrls)
        }

        return Promise.resolve(renderContentItem(contentUrl))
    }
}

const renderContent = (content) => {
    return (
        <div className="App">
            <div className="container-fluid">

                <div className="row">
                <div className="col">

                    {/* <header className="App-header">
                        <h1>VIKTOR MATTHEWS</h1>
                    </header> */}

                    <header>
                        <h1><span><a href="/">viktor matthews</a></span></h1>

                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button> */}

                            {/* <div className="collapse navbar-collapse row justify-content-center" id="navbarSupportedContent"> */}
                            <div className="row mx-auto" id="navbarSupportedContent">
                                <div className="col-auto links">                                
                                    <a href="https://patreon.com/viktormatthews">patreon</a> +&nbsp;
                                    <a href="https://bentbox.co/viktormatthews">bentbox</a> +&nbsp;
                                    <a href="https://instagram.com/viktormatthews">instagram</a> +&nbsp;
                                    <a href="https://twitter.com/viktormatthews">twitter</a>
                                </div>
                            </div>
                        </nav>
                    </header>
                </div>    
                </div>

                <div className="row my-5">
                    <div className="col frame">
                    { content }
                    </div>
                </div>

                <footer className="mt-5">copyright viktor matthews 2011 to infinity</footer>
            </div>
        </div>
    )
}

var renderedItemIdx = 0

const renderContentItem = (contentInfo, options={}) => {
    if (contentInfo == null) {
        throw new Error("contentInfo cannot be null")
    }

    if (!"content_url" in contentInfo) {
        throw new Error("contentInfo must contain key content_url")
    }

    const contentUrl = contentInfo.content_url

    // TODO use image title or tags as alt
    // TODO constrain video width on mobile or narrow browsers
    var content
    if (contentUrl.endsWith(".mp4")) {
        content = <ReactPlayer className="react-player" url={contentUrl} controls={true} playing={true} muted={true} loop={true} width="100%"/>
    } else {
        content = <img src={contentUrl} alt="hello" />
    }
    const extraKeyValues = Object.keys(contentInfo).map((k) => {
        const p = ( <p key={k}>{k}: {contentInfo[k]}</p> )
        return p
    })

    renderedItemIdx++

    // god/root mode
    const godMode = options.godMode

    // TODO: check if content is already liked, if so, show red heart
    // only shows interact buttons for godmode (for now)
    var interactDiv
    if (godMode == "1") {
        interactDiv = (
            <div className="col-sm">
                <a href="" onClick={ (e) => _contentClient.like(contentUrl, extras, options, e) }><img className="icon heart" src="icons/heart.png" alt="heart"/></a>
                {/* <button onClick={ (e) => console.log("button interact") }>hi</button> */}
            </div>
        )
    } else {
        interactDiv = ""
    }

    // only shows extras for godmode
    var extraKeyValuesDiv = ""
    if (godMode == "1") {
        extraKeyValuesDiv = (
            <div>
            { extraKeyValues }
            </div>
        )
    }

    const extraContentDiv = (
        <div className="col-sm">
            { ("purchase_links" in contentInfo) &&
                contentInfo.purchase_links.map((link, idx) => {
                    return <p key={`purchaselink-${idx}`}><a className="purchase" href={link.url}>{link.title}</a></p>
                })
                // <a className="purchase" href={contentInfo.purchase_link}>{contentInfo.title}</a>
            }

            { extraKeyValuesDiv }
        </div>
    )
    
    return ( 
        <div key={`contentItem-${renderedItemIdx}`} className="row contentItem">
        
        { interactDiv }

        <div className="col-lg">
        { content }
        </div>

        { extraContentDiv }

        </div>
    )
} 

class MuseumContentGenerator {

    generate(props) {
       return _contentClient.fetchRandom()
        .then(data => {
            console.log(`MuseumContentGenerator -> ${ JSON.stringify(data) }`)
            return renderContentItem(data)
        })
        // .catch(error => {
        //     console.error(error)
        //     throw error;
        // })
    }

}

// helps with no scrolling when first item is too short to show scrollbar
// https://github.com/ankeetmaini/react-infinite-scroll-component/issues/217
export function useTriggerScrollFix(deps) {
    useEffect(() => {
      if (typeof window !== 'undefined') {
        window.dispatchEvent(new CustomEvent('scroll'));
      }
    }, deps);
  }

const _contentClient = new ContentClient({shouldAuthenticate: true})

function InfiniteContentComponent() {    

    const query = new URLSearchParams(useLocation().search);

    const [items, setItems] = useState([])

    const [fetchedItemCount, setFetchedItemCount] = useState(0)

    const { modelName } = useParams();

    const [hasMore, setHasMore] = useState(true)

    console.log(`InfiniteContentComponent modelName:${modelName}`)

    useEffect(() => {
        console.log("InfiniteContentComponent useEffect")
    }, [])

    const next = () => {
        const nameParam = query.get("name")
        if (nameParam != null) {

        }

        let fetchPromise

        // TODO: index into results to avoid duplicates

        const opts = { startIndex: fetchedItemCount }
        if (modelName != null) {
            document.title = `VIKTOR MATTHEWS - ${modelName.toUpperCase()}`            
            fetchPromise = () => _contentClient.fetchByModelName(modelName, opts)
        } else {
            fetchPromise = () => _contentClient.fetchRandom(opts)
        }

        fetchPromise()
            .then(data => {
                // TODO: compute hasMore based on:
                // root json result - startIdx + fetchedItemCount
                const newHasMore = (data.item_count_total - (fetchedItemCount + data.items.length)) > 0
                setHasMore(newHasMore)

                console.log(`InfiniteContentComponent next fetchedItemCount:${fetchedItemCount} data.items.length:${data.items.length} hasMore:${hasMore} newHasMore:${newHasMore}`)

                setFetchedItemCount(fetchedItemCount + data.items.length)

                console.log(`[${fetchedItemCount}] InfiniteContentComponent next data:${JSON.stringify(data)}`)

                // if (!("content_url" in data)) {
                //     Promise.reject("no content_url in response data")
                // }

                const opts = { godMode: query.get("godmode") }
                const newItems = data.items.map(e => renderContentItem(e, opts))

                // this is weird right now, but I'm going to modify the endpoint to return multiple items soon
                const oldAndNewItems = [ 
                    ...items, 
                    ...newItems                            
                ]

                // NOTE: this caused data loading to stop after N items
                // truncates list to sensible size
                // if (newItems.length > 10) {
                //     newItems.shirt()
                // }

                setItems(oldAndNewItems)
                // console.log(`InfiniteContentComponent next newItems.length:${newItems.length}`)

                // TODO this doesn't work
                // if (newItems.length > 10) {
                //     throw new Error("stopping to debug")
                // }
            }).catch(error => {
                console.log("InfiniteContentComponent next catch")
                console.error(error)
            })
    }

    useTriggerScrollFix([items.length])

    console.log(`InfiniteContentComponent items.length:${items.length} hasMore:${hasMore}`)

    const content = <InfiniteScroll
        dataLength={ items.length }
        next={ next }
        hasMore={ hasMore }
        scrollThreshold={0.9}
        loader={ <p className="h1">LOADING</p> }
        endMessage={ <p className="h1">END</p> }
    >
        { items }
    </InfiniteScroll>

    return (
        renderContent(content)
    )

}

function NotFoundComponent() {
    const content = (
        <div className="row">
            <div className="col">
                <h2>page not found</h2>
            </div>
        </div>        
    )

    return (
        renderContent(content)
    )
}